export const ROUTES = {
  AUTH: {
    SIGN_IN: '/auth/sign-in',
    SIGN_UP: '/auth/sign-up',
    SIGN_OUT: '/auth/sign-out',
    RECOVERY: '/auth/recovery',
    ONBOARDING: {
      ROOT: '/auth/onboarding',
      EXAM_DATE: '/auth/onboarding/exam',
      DAILY_GOAL: '/auth/onboarding/daily-goal',
      UNIVERSITY: '/auth/onboarding/university',
      AVATAR: '/auth/onboarding/avatar',
    },
  },
  LEGAL: {
    PRIVACY_POLICY: '/legal/privacy-policy',
    TERMS_OF_SERVICE: '/legal/terms-of-service',
  },
  DECKS: {
    BROWSE: '/decks',
    DECK: ({ id }: { id: number }) => `/decks/${id}`,
  },
  EXAMS: {
    BROWSE: '/exams',
    DECK: ({ id }: { id: number }) => `/exams/${id}`,
  },
  PROFILE: '/profile',
  SOLVE: {
    DECK: ({ id }: { id: number }) => `/solve/${id}`,
  },
  RETAKE: '/retake',
  LANDING: {
    HERO: '/',
    REVIEWS: '/#reviews',
    FEATURES: '/#features',
    PRICING: '/#pricing',
  },
  ADMIN: {
    REVIEW: {
      BROWSE: '/admin/review',
      DECK: ({ id }: { id: number }) => ({
        BROWSE: `/admin/review/${id}`,
        SET: ({ id: setId }: { id: number }) => ({
          VIEW: `/admin/review/${id}/${setId}`,
          QUESTION: ({ id: questionId }: { id: number }) =>
            `/admin/review/${id}/${setId}?q=${questionId}`,
        }),
      }),
    },
    FEEDBACK: '/admin/feedback',
  },
  NOT_FOUND: '/404',
};
